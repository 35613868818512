.button-on-light,
.header-wrapper,
.header-background,
.breadcrumbs,
.plugin_images_gallery,
.contact-form-modal-advice,
.four-cards-services,
.three-cards,
.four-cards-content,
.four-cards-reference,
.icons-content,
.deitron-gmap,
.static-content-wrapper,
.static-content-wrapper-2,
.contact-wrapper,
.proven-expert,
.showcase-report-wrapper{
    page-break-inside: always;
    page-break-after: always;
    break-inside: always;
    page-break-before: always;
    display: none;
    clear: both;
}

.plugin-card-outer{
    page-break-inside: always;
    page-break-after: always;
    break-inside: always;
    page-break-before: always;
    clear: both;
}


.plugin-card-double-image-style-text{
    page-break-inside: always;
    page-break-after: always;
    break-inside: always;
    page-break-before: always;
    margin-top: 100px !important;
    clear: both;
}

.plugin-card-double-image-style-text a{
    display: none;
}

table{
    display: none;
}


.plugin-card-double-image-style-text .col{
    width: 100% !important;
}

.plugin-card-double-image-text .col{
    width: 100% !important;
}

.showcase-wrapper .plugin-card-double .col{
    float: none !important;
}
